@use "react-widgets/scss/styles.scss" with (
  $input-height: 2.5em,
  // Most component variables are forwarded with a namespace
  $list-selected-bg: #49c6de,
);

.rw-widget-container {
    .rw-input {
        margin-bottom: 0px;
        height: initial;
        border: 0px;
        height: 100% !important;
    }

    .rw-select {
        width: 0% !important;
    }

    .input-validation-error {
        background-color: initial !important;
        .rw-input {
            background-color: rgba(255,80,80,.4) !important;
            border: 0px;
            border-radius: 0%;
            height: 100% !important;
        }
        .rw-select {
            background-color: rgba(255,80,80,.4) !important;
            border: 0px;
        }
    }
}