.quick-search-container {
    display: flex;
    flex-flow: column wrap;

    .quick-search-icon {
        font-size: 30px;
        color: white;
        padding: 4px;
        text-shadow: 1px 1px #888
    }
}