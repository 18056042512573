.loading-spinner-root {
    display: flex;
    justify-content:  center;
    align-items:  center;
    height: 50vh;
}

.loading-spinner-message {
    text-align:center;
}

.loading-spinner-img {
    height:160px;
    width:160px
}