@use "sass:meta";
@use './react-components/stylesheet/index.scss';
@use './react-components/searches-results/print.scss';

@include meta.load-css('../node_modules/react-redux-toastr/lib/css/react-redux-toastr.min.css');

.m-0 {
    margin: 0px !important;
}

.no-padding {
    padding: 0;
}

.locta-plus-container .plus-result-container-v2 {
    min-height: 420px;
    height: 420px;
    width: 500px;
    float: left;
    border-left: 5px solid #4dbbe7;
    margin-top: 15px;

    .card-body {
        height: 80px;
    }
}

.b4, .ReactModalPortal {
    @include meta.load-css('../node_modules/bootstrap/scss/bootstrap.scss');

    p {
        margin-bottom: initial;
    }

    .b4-table {
        @extend .table;
    }

    .b4-table-sm {
        @extend .table-sm;
    }

    .b4-table-striped {
        @extend .table-striped;
    }

    .b4-table-bordered {
        @extend .table-bordered;
    }

    .b4-table-hover {
        @extend .table-hover;
    }

    .b4-modal {
        @extend .modal;
    }

    .b4-modal-open {
        @extend .modal-open;
    }

    .b4-fade {
        @extend .fade;
    }

    .b4-show {
        @extend .show;
        transition: opacity 0.3s;
        opacity: 1 !important;
    }

    .b4-modal-dialog {
        @extend .modal-dialog;
    }

    .b4-modal-dialog-centered {
        @extend .modal-dialog-centered;
    }

    .b4-modal-content {
        @extend .modal-content;
    }

    .b4-modal-header {
        @extend .modal-header;
    }

    .b4-modal-body {
        @extend .modal-body;
    }

    .b4-modal-footer {
        @extend .modal-footer;
    }

    .b4-modal-lg {
        @extend .modal-lg;
    }

    .b4-modal-sm {
        @extend .modal-sm;
    }

    .b4-form-control {
        @extend .form-control;
        zoom: initial !important;
        height: initial !important;
    }
    
    .b4-nav {
        @extend .nav;
    }

    .b4-nav-tabs {
        @extend .nav-tabs;
    }

    .b4-nav-item {
        @extend .nav-item;
    }

    button.b4-nav-link{
        @extend .nav-link;
        background-color: transparent;
        color: #007bff;
        padding: 0.5rem 2rem;
    }

    .b4-nav-link {
        @extend .nav-link;
    }

    .b4-btn {
        @extend .btn;
    }

    .b4-btn-success {
        @extend .btn-success;
    }

    .b4-btn-primary {
        @extend .btn-primary;
        background-color: #4dbbe7;
        border: none;

        &:hover {
            background-color: #45abd4;
            border: none;
        }

        &.submit-button {
            font-size: 14px;
            font-weight: 600;
            padding: 13px 29px;
            letter-spacing: 1px;
        }

        &[disabled] {
            background-color: #4dbbe7;
        }
    }

    .b4-btn-secondary {
        @extend .btn-secondary;
    }

    .b4-btn-danger {
        @extend .btn-danger;
        
    }

    .b4-btn-link {
        @extend .btn-link;
    }

    .b4-btn-warning {
        @extend .btn-warning;
        color: #f0f0f0;
        border: none;

        &:hover {
            color: #f0f0f0;
            border: none;
        }
    }

    .b4-btn-info {
        @extend .btn-info;
    }

    .b4-btn-sm {
        @extend .btn-sm;
        font-size: 12px;
        max-height: 28px;
    }

    .b4-badge {
        @extend .badge;
        line-height: 12px;
    }

    .b4-badge-success {
        @extend .badge-success;
    }

    .b4-badge-secondary {
        @extend .badge-secondary;
    }

    .b4-badge-danger {
        @extend .badge-danger;
    }

    .b4-text-danger{
        @extend .text-danger;
    }

    .b4-toast {
        @extend .toast;
    }

    .b4-toast-header {
        @extend .toast-header;
    }

    .b4-toast-body {
        @extend .toast-body;
    }

    .b4-spinner-border {
        @extend .spinner-border;
    }

    .b4-sr-only {
        @extend .sr-only;
    }

    .progress {
        background-image: linear-gradient(to bottom,#e5e5e5,#e9e9e9);
    }

    .modal.show {
        display: block !important;
        background-color: rgba(black, 0.8);
    }

    .modal-types {
        height: calc(100vh - 250px);
    }

    .btn-link {
        font-size: 12px;
    }

    .ch-show-more {
        margin-left: 26px;
        margin-bottom: 10px;
        margin-top: -5px;
    }

    .rw-cell {
        border: 0px;
    }

    .w-initial {
        width: initial;
    }

    .brg-sl-height-override {
        ul {
            height: 103px;
        }
    }

    .mt-20 {
        margin-top: 20px;
    }
}

.react-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
    transition: opacity .15s linear, visibility .15s linear;

    &.show {
        opacity: 0.8;
        visibility: visible;
    }
}

.react-modal-anchor {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    opacity: 0;
    visibility: hidden;

    display:flex;
    align-items: center;
    justify-content: center;

    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.react-modal {
    position: relative;
    margin-top: -2000px;
    min-width: 400px;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    background-color: #fff;
    border: 1px solid #999;
    border: 1px solid rgba(0,0,0,0.3);
    border-radius: 6px;
    box-shadow: 0 3px 7px rgba(0,0,0,0.3);
    background-clip: padding-box;
    transition: top .3s ease-out, height .3s ease-out, margin .3s ease-out;
    border-bottom: 8px solid #4dbbe7;
    padding: 5px;

    &.show {
        margin-top:0;
    }

    .react-modal-header {
        padding: 9px 15px;
        border-bottom: 1px solid #eee;
    }

    .react-modal-body {
        max-height: 400px;
        padding: 15px;
        overflow-y: auto;
        margin-bottom: 56px;
    }

    .react-modal-footer {
        position: absolute;
        bottom: 0;
        border-top: 1px solid #ddd;
        width: 100%;
        border-radius: 0 0 6px 6px;

        .content {
            padding: 15px;
            text-align: right;

            ul {
                margin: 0;
            }
        }

        .btn {
            margin-left: 10px;
        }
    }

    .close-button {
        @extend .b4-btn-primary;
        position: absolute;
        display: block;
        top: 12px;
        right: 12px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        border: none;
        color: #fff;
        border-radius: 2px;
    }

    &.add-to-profile,
    &.add-nino-alert,
    &.add-votersroll-alert,
    &.add-to-journal,
    &.email-council,
    &.print {
        padding: 10px;

        h1 {
            display: flex;
            justify-content: left;
            align-items: center;
            column-gap: 15px;
            padding-left: 15px;
            font-size: 2.5em;

            i {
                font-size: 24px;
            }
        }

        form {
            display:flex;
            flex-direction: column;
            gap:10px;
            margin:0;

            .p-15 {
                padding: 0 15px;
            }

            .field-row {
                display:flex;
                flex-direction: row;
            }
            .button-row {
                display:flex;
                flex-direction: row;
                gap:10px;
            }
        }

        pre {
            margin: 0;
        }

        input[type=checkbox] {
            margin-right:10px;
            margin-top: -2px;
        }
        label[for] {
            cursor: pointer;
        }
    }

    .confirmation {
        display:flex;
        flex-direction: column;
        gap:10px;
        margin:0;

        .button-row {
            text-align:center;

            > button {
                max-width: 200px;
            }
        }
    }
}

@include meta.load-css('../node_modules/react-table-v6/react-table.css');
.ReactTable {
    .expanded-table {
        .rt-expandable {
            display: none;
        }

        .company-tr {
            margin: 5px 0px -10px 5px;
        }

        .relevancy-tr {
            margin: 5px 0px -10px 0px;
        }
    }

    .records-table {
        border: 0;

        .rt-expandable {
            display: initial;
        }

        .-odd {
            background-color: initial !important;
        }
        .rt-tr-group {
            border: 0;
        }
    }

    .details-table {
        margin-left: 35px;

        .rt-tr-group {
            max-height: 30px;
        }
    }
}

.locta-react {
    @include meta.load-css('react-components/stylesheet/buttons.scss');
    @include meta.load-css('react-components/stylesheet/cards.scss');
}

.main-nav{
    z-index: initial;
}

.invalid-search-input {
    border-color: #f00 !important;
    border-width: 1px;
    border-style: solid;
}

.admin-edit {
    .existing-links {
        margin-top: 20px;

        li {
            line-height:30px;
        }
    }

    .btn-link {
        padding:0;
        margin:0;
        background-color: transparent;
        background-image: none;
        color: #00F;
        line-height:30px;
        font-weight:bold;

        &.red {
            color: #f00;
        }

        &.mr-20 {
            margin-right:20px;
        }
    }
}

.searches,
.edit-record {
    .checkbox-list {
        input[type=checkbox] {
            clear:left;
            float:left;
            margin-right:10px;
        }
        label {
            float:left;
        }
    }
}

.auto-search-loading {
    padding: 30px;
    text-align:center;
    background-color: white;
}

.colour-navy-blue {
    color: #00c;
    font-weight: 400;
}

.search-container {
    .form-search {
        margin-bottom: 0;
    }

    .block-grey {
        margin-bottom: 0px;
        border-radius: 0px;

        &.rt {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        &.rb {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    .record-active {
        border-left: 10px solid #5ab45a;
    }
    .record-inactive {
        border-left: 10px solid #f0f0f0;
    }
    
    .row-cell {
        max-height: 32px;
    }
}


.search-results {
    padding-top:10px;
    margin-bottom: 14px;
    
    .react-table-container {
        overflow:auto;
        min-height: 40vh;
        max-height: 40vh;
    }

    .block-header {
        h3 {
            color: #fff;
            font-size: 1em;
            /* 12px */
            line-height: 1;
            text-transform: uppercase;
            text-shadow: 0 1px 0 #2F7693;
        }

        &.has-tabs {
            padding-bottom: 0px;
            border-bottom: none;
        
            .nav-tabs {
                margin-left: 0px;
                margin-bottom: 0px;
                font-size: 0.9em;
                font-weight: bold;
                border-bottom: 1px solid transparent;
        
                li {
                    a {
                        padding: 4px 20px;
                        color: #4dbbe7;
                        background-color: #dddddd;
                    }
        
                    &:hover a,
                    &.active a {
                        color: #003972;
                        background-color: #ffffff;
                    }
                }
            }
        
            .option-tabs {
                margin-top: 2px;
                margin-bottom: 0px;
        
                li {
                    a,
                    button {
                        margin-left: -3px;
                        padding: 4px 20px;
                        color: #4dbbe7;
                        background-color: #dddddd;
                        display: inline-block;
                        zoom: 1;
                        text-decoration: underline;
                        border: none;
                        font-size: 0.9em;
                        font-weight: bold;
                        outline: none;
        
                        &:first-child {
                            -webkit-border-top-left-radius: 5px;
                            border-top-left-radius: 5px;
                        }
        
                        &:last-child {
                            -webkit-border-top-right-radius: 5px;
                            border-top-right-radius: 5px;
                        }
                    }
        
                    &:hover a,
                    &:hover button,
                    &.active a,
                    &.active button {
                        background-color: #ffffff;
                    }
                }
            }
        }
    }

    .results-group {
        hr.dashed {
            border-style: dashed;
        }

        .ReactTable {
            border: none;
        
            .results-col {
                box-sizing: content-box;
            }

            .rt-pagination {
                height: 40px;
                width: 500px;
                margin: 0 auto;
                padding-top: 1px;
                text-align: center;

                ul {
                    display: inline-block;
                    zoom: 1;
                    margin-bottom: 0;
                    margin-left: 0;
                    border-radius: 3px;
                    box-shadow: 0 1px 2px rgba(0,0,0,0.05);

                    & > li {
                        display: inline;
                        padding: 0px !important;

                        & > button {
                            float: left;
                            text-decoration: none;
                            background-color: #fff;
                            border: 1px solid #ddd;
                            border-left-width: 0;
                            padding: 0 6px;
                            line-height: 26px;
                            font-size: 12px;
                            color: #4dbbe7;

                            &:hover {
                                background-color: #f5f5f5;
                            }
                        }

                        &:first-child > button {
                            border-left-width: 1px;
                            border-radius: 3px 0 0 3px;
                        }

                        &:last-child > button {
                            border-radius: 0 3px 3px 0;
                        }
                    }

                    .active > button {
                        color: #999;
                        cursor: default;
                        background-color: #f5f5f5;
                    }

                    .disabled > button {
                        color: #999;
                        cursor: default;
                        background-color: transparent;
                    }
                }
            }

            .rt-thead {
                display:none;
            }
            .rt-tr {
                min-height: 60px;
                overflow: hidden;
                border-bottom: 1px solid #fff;
                background-color: #fff;
                background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#ececec));
                background-image: -webkit-linear-gradient(top, #ffffff, #ececec);
                background-image: -moz-linear-gradient(top, #ffffff, #ececec);
                background-image: -o-linear-gradient(top, #ffffff, #ececec);
                background-image: linear-gradient(to bottom, #ffffff, #ececec);

                .rt-td {
                    border-right:none;
                    padding: initial;
                    white-space: initial;
                    width: auto !important;
                }

                span.blue {
                    color: #003972;
                }
            }
        }
    }

    .result-options {
        line-height: 40px;
        border-bottom: solid 1px #4dbbe7;
        height: 40px;
    
        & > div > a {
            font-size: 0.9em;
            margin-left: 10px;
            margin-right: 10px;
        }
    
        & > div > select {
            width: 44px;
            padding: 0px;
            height: 20px;
            margin-top: 5px;
            font-size: 0.9em;
        }
    
        & .results-per-page {
            float: left;
        }
    }
}

.search-results {
    .results-group.telephone-directory-results-group {
        .ReactTable {
            .rt-tr {
                background-image: none;
            }
        }
    }
    th {
        input, textarea, .uneditable-input {
            width: 100%;
        }
    }
}