.quick-search-results {
    align-items: center;
    width: 100%;

    .quick-search-item {
        margin-left: 10%;
        width: 80%;
        padding: 12px 0 12px 0px;
        float:left;

        .card-label {
            line-height:28px;
        }
    }

    form {
        margin:0 ;
    }

    .quick-search-alert {
        .body {
            padding:10px 20px !important;
        }
    
        .alert-description {
            padding-top: 10px;
            padding-bottom: 20px;
        }
        
        .form-group .col:first-child {
            font-weight:bold;
        }

        .invalid-search-input {
            border-color: #f00 !important;
        }
    }
}

