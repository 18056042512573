.warning-card, .locta-card {
    .header {
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
        border-radius: 5px;
        padding: 12px 16px;
    }

    .body {
        padding: 10px 16px;
    }

    h3 {
        font-size: 1em;
        line-height: 1;
        text-transform: uppercase;
        margin: 0;
        font-weight: normal;
    }

    &.blue {
        .header {
            border: 1px solid #44abd5;
            background-color: #4dbbe7;
            background-image: linear-gradient(to bottom, #4dbbe7, #45abd4);
        }
    
        h3 {
            color: #fff;
            text-shadow: 0 1px 0 #2F7693;
        }
    }

    &.orange {
        .header {
            border: 1px solid rgb(235, 145, 0);
            background-color: rgb(255, 165, 0);
            background-image: linear-gradient(to bottom, rgb(255, 175, 0),  rgb(235, 145, 0) );
        }
    
        h3 {
            color: #fff;
            text-shadow: 0 1px 0 rgb(155, 65, 0);
        }
    }
}