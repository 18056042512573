.locta-form {
    .input-row {
        height: 30px;
    }

    .react-datepicker-wrapper{
        input{
            height: 30px;
        }

        .react-datepicker__close-icon::after {
            background-color: lightgray;
            color: black;
            border-radius: 25%;
        }
    }
}