.quick-search-results {
  align-items: center;
  width: 100%;
}
.quick-search-results .quick-search-item {
  margin-left: 10%;
  width: 80%;
  padding: 12px 0 12px 0px;
  float: left;
}
.quick-search-results .quick-search-item .card-label {
  line-height: 28px;
}
.quick-search-results form {
  margin: 0;
}
.quick-search-results .quick-search-alert .body {
  padding: 10px 20px !important;
}
.quick-search-results .quick-search-alert .alert-description {
  padding-top: 10px;
  padding-bottom: 20px;
}
.quick-search-results .quick-search-alert .form-group .col:first-child {
  font-weight: bold;
}
.quick-search-results .quick-search-alert .invalid-search-input {
  border-color: #f00 !important;
}

