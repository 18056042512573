.postcode-input-container {
    display: flex;
    justify-content:  center;
    align-items:  center;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    width:156px;
    padding: 4px 6px;

    &.error {
        border-color: #f00;
    }

    .postcode-input-digit {
        border: none !important;
        border-bottom: 1px solid #aaa !important;
        width: 12px !important;
        padding: 0 1px;
        margin: 0 2px;
        border-radius: 0;
        text-align: center;

        &.margin-right-bigger {
            margin-right:14px;
        }
    }

    .postcode-input-clear-button {
        background: transparent;
        border: none;
        padding: 0 2px;
        margin: 0 0 0 8px;
        color: red;
        font-size: 12px;
    }
}